import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReportsInterface, ReportsStoreInterface } from 'modules/reports/interfaces/reports.interfaces';
import { Meta } from 'modules/shared/interfaces';

const initialState: ReportsStoreInterface = {
  data: [],
  loading: false,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setReports(state, action: PayloadAction<ReportsInterface[]>) {
      state.data = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
  },
});

export const reportsActions = reportsSlice.actions;

export default reportsSlice;
